import * as React from "react"
import { SEO } from "../../components/seo"
import Layout from '../../components/vi/layout';
import { StaticImage } from "gatsby-plugin-image"
import ScrollAnimation from 'react-animate-on-scroll';
// import { Link } from "gatsby";

const AboutPage = (props) => {
  return (
    <Layout pathname={props.location.pathname}>
      <section className="
      h-[600px]
      lg:h-[750px]
      bg-primary
      bg-[url('../images/about-kim-hero-mobile.jpg')]
      md:bg-[url('../images/about-kim-hero.jpg')]
      bg-no-repeat
      bg-center
      bg-cover
      relative">
        <div className="absolute w-full lg:h-3/5 inset-X-0 bottom-0 pt-28 pb-20 lg:pt-10 lg:pb-20 z-10 bg-gradient-to-t from-primary px-4 lg:px-20 lg:flex lg:flex-col lg:justify-end text-center">
        <h1 className="animate__animated animate__fadeInDown animate__slower font-heading text-4xl md:text-6xl text-white mb-2">Cô <span className="font-bold text-secondary">Kim Nguyễn-Penaloza</span></h1>
          <p className="animate__animated animate__fadeInUp animate__slower text-xl font-light text-white drop-shadow-sm leading-relaxed">là con của gia đình một người tị nạn Việt Nam và một người di dân Mexico, câu chuyện của cô Kim là một câu chuyện tiêu biểu của California về tinh thần hy sinh, làm việc chăm chỉ, và tận tụy với cộng đồng.</p>
        </div>
      </section>
      <section className="w-full mt-4 lg:mt-14 mx-auto px-4 lg:px-36 text-gray-700 flex flex-col lg:flex-row items-start justify-center">
        <div className="w-full lg:w-1/4 columns-2 order-last lg:order-first">
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
            <StaticImage src="../images/about-kim/about-kim-01.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" />
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
            <StaticImage src="../images/about-kim/about-kim-02.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" />
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
            <StaticImage src="../images/about-kim/about-kim-03.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" />
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
            <StaticImage src="../images/kim-and-dog.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" />
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
            <StaticImage src="../images/about-kim/about-kim-04.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" />
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
            <StaticImage src="../images/about-kim/about-kim-06.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" />
          </ScrollAnimation>
        </div>
        <div className="text-base leading-loose space-y-6 pb-10 w-full lg:w-1/2 lg:px-12">
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><p>Cô Kim Nguyễn-Penaloza được nuôi dạy bởi cha là người Việt Nam và mẹ là người Mexico, từ nhỏ cô Kim Nguyễn-Penaloza đã được học cách tự phấn đấu để đạt được thành công cho bản thân. Cô là người đầu tiên trong gia đình tốt nghiệp trung học và đại học. Khi chỉ mới 25 tuổi, cô đắc cử Nghị Viên Hội Đồng Thành Phố Garden Grove, trở thành người trẻ tuổi nhất được bầu vào chức vụ này. Năm 2020, cô Kim tái đắc cử một cách áp đảo với hơn 75% phiếu bầu.</p></ScrollAnimation>
  
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><p>Với vai trò Nghị Viên, cô Kim là người tranh đấu mạnh mẽ cho lợi ích của các khu dân cư, thúc đẩy phát triển kinh tế, các dự án hạ tầng cơ sở và cải thiện công viên, đồng thời giảm thiểu tội phạm và vô gia cư. Cô Kim là một người lãnh đạo trong các vấn đề nữ giới, chống lại hành vi quấy rối, và tranh đấu cho sự bình đẳng và công bằng trên khắp miền Nam California. Cô đã tranh đấu để cho những người di dân như mẹ của cô, có cuộc sống đầy nhân phẩm và cơ hội để vươn lên trong xã hội của chúng ta.</p></ScrollAnimation>
  
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><p>Khi đắc cử vào Quốc Hội Hoa Kỳ, cô Kim sẽ làm việc để khôi phục tính thanh liêm và trách nhiệm cho nguồn máy lập pháp ở Hoa Thịnh Đốn. Cô đã chứng minh khả năng làm việc với lưỡng đảng, cùng với đồng viện trong hội đồng thành phố đưa ra những chính sách hữu ích nhằm củng cố các doanh nghiệp tiểu thương và tạo cơ hội kinh tế. Cô Kim hiểu rõ những thách thức mà chúng ta đối mặt tại miền Nam California, đặc biệt là sự gia tăng chi phí sinh hoạt, nhà ở, và giáo dục và y tế. Khi vào Quốc Hội Hoa Kỳ, cô sẽ làm việc để giảm chi phí y tế và thuốc men, nâng khả năng có được nhà ở với giá phải chăng, đầu tư vào huấn nghiệp để giúp tăng mức lương và giảm thiểu lạm phát để các gia đình lao động ở Nam California có thể vươn lên.</p></ScrollAnimation>

          <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><p>Là một cư dân lâu năm của Địa Hạt CA-45, cô Kim từng theo học tại các trường Tiểu học Clinton-Mendenhall, Doig Intermediate, và Trung học Santiago. Cô Kim sẽ mang kiến thức nền tảng của mình về y tế công cộng vào trong Quốc Hội, với kinh nghiệm cá nhân cô từng làm việc cho cơ quan y tế CalOptima và hiện là quản lý chương trình cho một tổ hợp y tế lớn.</p></ScrollAnimation>

        </div>
        <div className="w-full lg:w-1/4 columns-2">
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
            <StaticImage src="../images/about-kim/about-kim-07.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" />
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
            <StaticImage src="../images/about-kim/about-kim-08.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" />
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
            <StaticImage src="../images/about-kim/about-kim-09.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" />
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
            <StaticImage src="../images/about-kim/about-kim-10.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" />
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
            <StaticImage src="../images/about-kim/about-kim-11.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" />
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
            <StaticImage src="../images/about-kim/about-kim-12.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" />
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
            <StaticImage src="../images/about-kim/about-kim-13.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" />
          </ScrollAnimation>
        </div>
      </section>
    </Layout>
  )
}

export default AboutPage

export const Head = ({location}) => <SEO title="Tiểu Sử" pathname={location.pathname}  />
